import React, { useEffect, useState } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { createUseStyles } from 'react-jss';
import Selects from '@/helpers/selects';
import { listRooms } from '@/api/roomAPI';
import { sessionSelector } from '@/store/selector';

const useStyles = createUseStyles(
  {
    root: {
      '& .ui.image': {
        height: 16,
        marginTop: 'auto !important',
      },
    },
  },
  { name: 'RoomDropdown' },
);

const RoomDropdown = ({ onRoomChange }) => {
  const classes = useStyles();
  const [state, setState] = useState({ roomList: [], selectedRoom: null });
  const session = sessionSelector();
  const user = session.user;

  useEffect(() => {
    listRooms().then((res) => {
      let roomList = res.data.result.map((room) => ({
        ...Selects.getPropertyAsOption({
          id: room.id,
          name: 'Unit ' + room.roomNumber + ' - ' + room.roomType.gridName,
        }),
        /* image: { src: '/assets/marker.svg', avatar: true }, */
      }));
      setState({ selectedRoom: null, roomList: roomList });
    });
  }, [user?.selectedProperty]);

  const { roomList, selectedRoom } = state;

  const onChange = (_, { value }) => {
    setState({ ...state, selectedRoom: value });
    onRoomChange(value);
  };

  return (
    <Dropdown
      className={classes.root}
      options={roomList}
      selection
      onChange={onChange}
      value={selectedRoom}
      placeholder="Please choose a room"
      selectOnBlur={false}
    />
  );
};

export default React.memo(RoomDropdown);
