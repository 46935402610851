import { useEffect, useState } from 'react';
import { Routes } from 'react-router-dom';
import TopBarProgress from 'react-topbar-progress-indicator';
import { BrowserRequestListener } from 'browser-request-listener';

TopBarProgress.config({
  barThickness: 5,
});

const CustomRoutes = ({ children }) => {
  const [progress, setProgress] = useState(false);
  /*const [prevLoc, setPrevLoc] = useState('');
  const location = useLocation();*/
  let browserRequestListener = null;

  /* useEffect(() => {
    setPrevLoc(location.pathname);
    setProgress(true);
    if (location.pathname === prevLoc) {
      setPrevLoc('');
      //thanks to ankit sahu
    }
  }, [location]); 

  useEffect(() => {
    setProgress(false);
  }, [prevLoc]); */

  useEffect(() => {
    browserRequestListener = new BrowserRequestListener({
      reportOnError: function (error, event) {
        console.log(event);
        console.log('report error', error);
        setProgress(false);
      },
      filters: {
        // disableForXHr : true
        // disableForFetch : true,
      },
    });

    browserRequestListener.addPreHttpRequestListener(function (
      params,
      /**@type {Sender}  */ sender,
    ) {
      if (sender.getSenderType() == 'XHR') {
        //console.log('called before XHR', params, sender);
        setProgress(true);
      } else if (sender.getSenderType() == 'FETCH_API') {
        //console.log('called before Fetch API', params, sender);
        setProgress(true);
      }
    });

    browserRequestListener.addPostHttpRequestListener(function (
      response,
      /**@type {Sender}  */ sender,
    ) {
      if (sender.getSenderType() == 'XHR') {
        //console.log('called after XML http request', response, sender);
        setProgress(false);
      } else if (sender.getSenderType() == 'FETCH_API') {
        //console.log('called after Fetch API', response, sender);
        setProgress(false);
      }
    });

    browserRequestListener.apply();
  }, []);

  return (
    <>
      {progress && <TopBarProgress />}
      <Routes>{children}</Routes>
    </>
  );
};

export default CustomRoutes;
