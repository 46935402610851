import React from 'react';
import { createUseStyles } from 'react-jss';
import classnames from 'classnames';

const useStyles = createUseStyles(
  {
    root: {
      display: 'inline-grid',
      '& > *': {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
      }
    }
  },
  { name: 'EllipsisText' }
);

const EllipsisText = ({ className, children }) => {
  const classes = useStyles();

  return <div className={classnames(classes.root, className)}>{children}</div>;
};

export default React.memo(EllipsisText);
