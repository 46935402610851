import React from 'react';
import { Button, Image } from 'semantic-ui-react';
import classnames from 'classnames';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(
  {
    root: {
      height: 36,
      '&.ui.button': {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center'
      }
    },
    image: {
      '&.ui.avatar.image': {
        width: 'auto',
        height: 'auto',
        marginRight: 0,
        borderRadius: 0
      }
    },
    text: {
      marginLeft: 10
    }
  },
  { name: 'ImageButton' }
);

const ImageButton = ({
  onClick,
  src,
  text,
  style,
  loading = false,
  disabled = false
}) => {
  const classes = useStyles();

  return (
    <Button
      className={classnames('white', classes.root)}
      style={style}
      onClick={onClick}
      loading={loading}
      disabled={disabled}
    >
      <Image className={classes.image} src={src} avatar />
      {!!text && <span className={classes.text}>{text}</span>}
    </Button>
  );
};

export default React.memo(ImageButton);
